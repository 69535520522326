@use '../abstracts/colors' as *;

@mixin themify($themes-main) {
    @each $name, $values in $themes-main {
      .#{$name} {
        $theme-map: $values !global;
        @content;
      }
    }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

@include themify($themes-main) {
   
    #IoID-faq {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 24px;

        .accordion-body{

            font-family: Arial, Helvetica, sans-serif;
            font-size: 0.9rem;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
        }
        .accordion-button {
            font-family: Arial, Helvetica, sans-serif;
        }

        .accordion{
            color: white;
            .accordion-item {
                border-bottom:  1px solid themed('border-color');
                border-top: none;
                border-left: none;
                border-right: none;
                border-radius: 5px;
                font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
                background-color: transparent;

                .accordion-collapse{
                    border-top: 1px solid themed('border-color');
                }
    
                .accordion-body{
                    color: themed('header-text-color');
                    margin: 1em 0em;
                }
    
            }
    
            .accordion-header{
                padding-bottom: 0.5em;
                padding-top: 0.5em;
    
                &:hover{
                    background-color: themed('header-hover');
                }
                .accordion-button {
                    color: themed('header-text-color');
                    border: none;
                    font-size: 1.25rem;
                    line-height: 28px;
                    background-color: transparent;
                    
                    &::after {
                        border-radius: 100px;
                        border-color: rgb(0, 0, 0);
                        transform:rotate(180deg);
                        background-image: themed('bg-image-url');
                    }
                    &:not(.collapsed){
                        box-shadow: none;
                    }
                    &:not(.collapsed)::after{
                        transform:rotate(0deg);
                    }
    
                    &:focus{
                        box-shadow: none;
                    }
    
                }
    
            }
        }
    }
  };


@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

$themes: (
  light: (
    spiderweb-path-stroke: #000000,
    spiderweb-line-stroke: #000000,
    spiderweb-polyline-stroke: #000000,

    od-text-color: #000000,


    eic-circle-stroke: #000000,

    
  ),
  dark: (
    spiderweb-path-stroke: #FFFFFF,
    spiderweb-line-stroke: #FFFFFF,
    spiderweb-polyline-stroke: #FFFFFF,

    od-text-color: #FFFFFF,

    eic-circle-stroke: #FFFFFF,


  ),
);

@mixin themify($themes) {
  @each $name, $values in $themes {
    .#{$name} {
      $theme-map: $values !global;
      @content;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

@include themify($themes) {

  #IoID-technical{
    // font-family: "Google Sans",Roboto,Arial,sans-serif;
    .iphonex {
      
      width: 20em;
      height: 40em;
      display: flex;
      position: relative;
      transform: rotate(0deg);
      transition: all cubic-bezier(0.36, 0.41, 0.38, 1) 0.4s;
      z-index: 10;
      &:hover{
        margin-right: 0%;
        .back{
          box-shadow: 0 0 15em rgba(70, 70, 70, 0.4);
        }
  
      }
  
      .back{
        display: flex;
        flex: 1;
        margin: 0.2em;
        border-radius: 3em;
        position: absolute;
        z-index: 1;
        width: calc( 100% - 0.6em );
        height: calc( 100% - 0.6em );
        top: 0;
        right: 0;
        box-shadow: 0 0 10em rgba(70, 70, 70, 0.1);
        transition: all 0.4s;
      }
  
      .front{
        display: flex;
        flex: 1;
        background-color: #292c2d;
        border-radius: 3em;
        margin: 0.2em;
        overflow: hidden;
        position: relative;
        box-shadow: 0 0.1em 0.4em rgba(255, 255, 255, 0.1) inset;
        z-index: 10;
      }
  
      .screen{
        font-family: "Google Sans",Roboto,Arial,sans-serif;
        display: flex;
        flex: 1;
        background-image: linear-gradient(to bottom, #000000, #050404, #090909, #0d0d0d, #111010);
        margin: 0.4em;
        border-radius: 2.6em;
        border: solid 0.2em #121415;
        position: relative;
        z-index: 10;
  
        .screen__view{
          display: flex;
          flex: 1;
          margin: 0.6em;
          border-radius: 2em;
          overflow: hidden;
          position: relative;
          width: 100%;  
          align-items: center;
          justify-content: center;
  
          &::after{
            transform:  translateY(100%) scaleX(1.4);
            opacity: 0.3;
            transition: all ease 0.5s 0.1s;
          }
  
          &::before{
            transform:  translateY(100%) scaleX(1.4);
            opacity: 1;
            z-index: 40;
            transition: all ease 0.6s;
          }
  
          .dribbbleLogo{
            svg{
              width: 8em;
              height: 8em;
            }
            position: absolute;
            z-index: 10;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all ease 1s;
  
            &.white{
              width: 0.9em;
              height: 0.9em;
              border: solid 0.07em #fff;
              position: relative;
              margin-left: 0.3em;
              background: transparent;
              box-sizing: border-box;
            }
  
          }
  
          .phone-app{
            padding: 2em 1em;
            opacity: 0;
            font-weight: 400;
            color: rgb(0, 0, 0);
            background: rgb(255, 255, 255);
            z-index: 60;
            opacity: 0;
            border-radius: 2em;

            transition: all ease 0.3s;
            transform: translateY(0%); // 40%
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
  
            .app-div{
              font-size: 0.9em !important;
              font-weight: 400;
  
              .skeleton {
                background-color: #e2e5e7;
                // The shine that's going to move across the skeleton:
                background-image:			
                    linear-gradient(
                      90deg, 
                      rgba(#fff, 0), 
                      rgba(rgba(255, 255, 255, 0.622), 0.5),
                      rgba(#fff, 0)
                    );
                background-size: 40px 100%; // width of the shine
                background-repeat: no-repeat; // No need to repeat the shine effect
                background-position: left -40px top 0; // Place shine on the left side, with offset on the left based on the width of the shine - see background-size
                animation: shine 1s ease infinite; // increase animation time to see effect in 'slow-mo'
              }
              
              @keyframes shine {
                to {
                  // Move shine from left to right, with offset on the right based on the width of the shine - see background-size
                  background-position: right -40px top 0;
                }
              }
              
              .app-circle{
                width: 2em;
                height: 2em;
                background-color: #CFD2CF;
                border-radius: 50%;
                margin-bottom: 6px;
              }
  
              .app-access-set{
                width: 2em;
                height: 2em;
              }
  
              .app-circle-2{
                width: 1.5em;
                height: 1.5em;
                background-color: #CFD2CF;
                border-radius: 50%;
              }
  
              .app-line-short{
                width: 30%;
                height: 0.8em;
                background-color: #CFD2CF;
                border-radius: 10px;
                margin-bottom: 0.4em;
              }
  
              .app-line{
                width: 60%;
                height: 0.8em;
                background-color: #CFD2CF;
                border-radius: 10px;
                margin-bottom: 0.4em;
              }
  
              .app-line-long{
                width: 75%;
                height: 0.8em;
                background-color: #CFD2CF;
                border-radius: 10px;
                margin-bottom: 0.4em;
              }
  
              
              .app-line-full{
                width: 90%;
                height: 0.8em;
                background-color: #CFD2CF;
                border-radius: 10px;
                margin-bottom: 0.4em;
              }
  
              .app-info{
                span{
                  font-weight: 500;
                  color: #174ea6;
                  svg{
                    color: #515455;
                  }
                }
              }
  
              .button-phone{
                align-items: center;
                appearance: none;
                background-color: #fff;
                border-radius: 24px;
                border-style: none;
                box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
                box-sizing: border-box;
                cursor: pointer;
                display: inline-flex;
                fill: currentcolor;
                font-size: 14px;
                font-weight: 500;
                height: 48px;
                justify-content: center;
                letter-spacing: .25px;
                line-height: normal;
                max-width: 100%;
                overflow: visible;
                padding: 0px 12px;
                position: relative;
                text-align: center;
                text-transform: none;
                user-select: none;
                -webkit-user-select: none;
                touch-action: manipulation;
                width: auto;
                z-index: 0;
                
                &.green-btn{
                  color: #ffffff;
                  background: rgb(47, 134, 166);
                  
                }
                &.red-btn{
                  color: #000000;
                  background: transparent;
                }
  
                &:focus-visible {
                  box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px;
                  transition: box-shadow .2s;
                }
  
                &:active {
                  background-color: #F7F7F7;
                  border-color: #000000;
                  transform: scale(.96);
                }
                
                &:disabled {
                  border-color: #DDDDDD;
                  color: #DDDDDD;
                  cursor: not-allowed;
                  opacity: 1;
                }
  
                &:hover {
                  background: #F6F9FE;
                  color: #174ea6;
                }
                
                &:active {
                  box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%), 0 8px 12px 6px rgb(60 64 67 / 15%);
                  outline: none;
                }
                
                &:focus {
                  outline: none;
                  border: 0.5px solid #4285f4;
                }
                
                &:not(:disabled) {
                  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
                }
                
                &:not(:disabled):hover {
                  box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
                }
                
                &:not(:disabled):focus {
                  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
                }
                
                &:not(:disabled):active {
                  box-shadow: rgba(60, 64, 67, .3) 0 4px 4px 0, rgba(60, 64, 67, .15) 0 8px 12px 6px;
                }
                
                &:disabled {
                  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
                }
  
              }
  
            }
  
          }
          &:after,
          &:before{
            content: "";
            position: absolute;
            z-index: 1;
            width: 50em;
            height: 50em;
            border-radius: 50%;
            bottom: 0;
          }
        }
  
        .screen__front{
          position: absolute;
          width: 50%;
          background-color: #000000;
          height: 1.8em;
          border-radius: 0 0 0.9em 0.9em;
          right: 25%;
          top: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-bottom: 0.3em;
          box-sizing: border-box;
          margin-top: 0.5em;
          z-index: 999;
  
          &::after{
            left:1.4em;
            transform: translateX(-100%);
            border-left: 0;
            border-radius: 0 0.7em 0 0;
          }
          &::before{
            right:0.4em;
            transform: translateX(100%);
            border-right: 0;
            border-radius: 0.7em 0 0 0;
          }
  
          &-speaker{
            background: #4b4f4f;
            border-radius: 0.2em;
            height: 0.35em;
            width: 28%;
          }
  
          &-camera{
            height: 0.35em;
            width: 0.35em;
            background: #0b5be76a;
            margin-left: .5em;
            border-radius: 50%;
            margin-right: -0.8em;
          }
  
          &::after,
          &::before{
            content: "";
            width: 10%;
            height: 50%;
            position: absolute;
            background: transparent;
            top: -0.3em;
            border: solid 0.4em linear-gradient(to bottom, #000000, #050404, #090909, #0d0d0d, #111010);;
            border-bottom: 0;
          }
          
        }
  
        &:hover{
  
          .screen__view{
  
            .phone-app{
              opacity: 1;
              transform: translateY(0%);
              transition: all ease 0.6s 0.7s;
            }
  
            &::after{
              transform:  translateY(10%) scaleX(1.4);
              transition: all ease 0.9s;
            }
            
            &::before{
              transform:  translateY(10%) scaleX(1.4);
              transition: all ease 0.9s 0.2s;
            }
  
            .dribbbleLogo{
              &:not(.white){
                opacity: 0;
                transform: translateY(300%) translateZ(1px);
                transition: all ease 2s 0.1s
              }
            
            }
  
          }
  
        }
  
      }
  
      .front__line{
        background: linear-gradient(to right, #515455, #454748, #515455);
        position: absolute;
        z-index: 8;
        width: 100%;
        height: 0.5em;
        top: 10%;
  
        &-second {
          top: 90%
        }
        
      }
  
      .phoneButtons{
        width: 1em;
        height: 6em;
        position: absolute;
        z-index: 2;
        background: linear-gradient(to bottom, #212324, #2b2e31,#212324);
        box-shadow: 0 0 0.4em rgba(255, 255, 255, 0.1) inset;
        border-radius: 2px;
      }
      
      .phoneButtons-right{
        right: 0;
        top: 30%;
      }
  
      .phoneButtons-left{
        left: 0;
        top: 15%;
        height: 3em;
      }
  
      .phoneButtons-left2{
        left: 0;
        top: 25%;
        height: 5em;
      }
  
      .phoneButtons-left3{
        left: 0;
        top: calc( 25% + 6em);
        height: 5em;
      }
      
    }
    
    //@media (max-height: 40em){
    //
    //  .iphonex {
    //    width: 48vh;
    //    height: 94vh;
    //  }
    //  
    //}
  
   
    .navigation{
      display:flex;
      width:100%;
      height:60px;
      background: #d5dadd;
      list-style:none;
      top: auto;
      bottom: 0px;
      margin: 0px;
      border-radius: 0.5em 0.5em 0.9em 0.9em;;
    }
    
  
    input[type='checkbox']{
      --active: #275EFE;
      --active-inner: #fff;
      --focus: 2px rgba(39, 94, 254, .3);
      --border: #BBC1E1;
      --border-hover: #275EFE;
      --background: #fff;
      --disabled: #F6F8FF;
      --disabled-inner: #E1E6F9;
      -webkit-appearance: none;
      -moz-appearance: none;
      height: 21px;
      outline: none;
      display: inline-block;
      vertical-align: top;
      position: relative;
      margin: 0;
      cursor: pointer;
      border: 1px solid var(--bc, var(--border));
      background: var(--b, var(--background));
      transition: background .3s, border-color .3s, box-shadow .2s;
      &:after {
        content: '';
        display: block;
        left: 0;
        top: 0;
        position: absolute;
        transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
      }
      &:checked {
        --b: var(--active);
        --bc: var(--active);
        --d-o: .3s;
        --d-t: .6s;
        --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
      }
      &:disabled {
        --b: var(--disabled);
        cursor: not-allowed;
        opacity: .9;
        &:checked {
          --b: var(--disabled-inner);
          --bc: var(--border);
        }
        & + label {
          cursor: not-allowed;
        }
      }
      &:hover {
        &:not(:checked) {
          &:not(:disabled) {
            --bc: var(--border-hover);
          }
        }
      }
      &:focus {
        box-shadow: 0 0 0 var(--focus);
      }
      &:not(.switch) {
        width: 21px;
        &:after {
          opacity: var(--o, 0);
        }
        &:checked {
          --o: 1;
        }
      }
      & + label {
        font-size: 14px;
        line-height: 21px;
        display: inline-block;
        vertical-align: top;
        cursor: pointer;
        margin-left: 4px;
      }
    }
  
    input[type='checkbox'] {
      &:not(.switch) {
        border-radius: 7px;
        &:after {
          width: 5px;
          height: 9px;
          border: 2px solid var(--active-inner);
          border-top: 0;
          border-left: 0;
          left: 7px;
          top: 4px;
          transform: rotate(var(--r, 20deg));
        }
        &:checked {
          --r: 43deg;
        }
      }
      &.switch {
        width: 38px;
        border-radius: 11px;
        &:after {
          left: 2px;
          top: 2px;
          border-radius: 50%;
          width: 15px;
          height: 15px;
          background: var(--ab, var(--border));
          transform: translateX(var(--x, 0));
        }
        &:checked {
          --ab: var(--active-inner);
          --x: 17px;
        }
        &:disabled {
          &:not(:checked) {
            &:after {
              opacity: .6;
            }
          }
        }
      }
    }

    #IoID-od{
      svg{
        #dot-1,
        #dot-2,
        #dot-3,
        #dot-4,
        #bot-account,
        #bot-account-2,
        #unused-account,
        #unused-account-2,
        #active-account,
        #active-account-2,
        #subscriber-follower,
        #like-dislike,
        #views,
        #click,
        #survey
          {
            path{
              fill: themed('od-text-color');
            }
          }
      }
    }

    #IoID-etc{
      svg{
        #c{
          path{
            stroke: themed('eic-circle-stroke');
          }
        }
      }
    }
  
    #IoID-spiderweb{
      svg{

        #agotest-s-line1{
            stroke: themed('spiderweb-path-stroke');
        }

        #agotest-u-ailineyes, 
        #agotest-u-ailineno,
        #agotest-u-yes, 
        #agotest-u-no, 
        #agotest-u-governmentlineyes,
        #agotest-u-governmentlineno,
        #agotest-u-spiderlineno,
        #agotest-u-spiderlineyes,
        #agotest-u-mailline,
        #agotest-u-spiderline1,
        #agotest-u-spiderline2,
        #agotest-u-spiderline3,
        #agotest-u-spiderline4{
          path{
            stroke: themed('spiderweb-path-stroke');
          }
          text{
            fill: themed('spiderweb-path-stroke');
          }
          line{
            stroke: themed('spiderweb-path-stroke');
          }
          polyline{
            stroke: themed('spiderweb-path-stroke');
          }
        }
      }
    }
  }
}

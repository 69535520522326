@use '../abstracts/colors' as *;

@mixin themify($themes-main) {
    @each $name, $values in $themes-main {
      .#{$name} {
        $theme-map: $values !global;
        @content;
      }
    }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

@include themify($themes-main) {
    #IoID-Careers{
        .header-text{
            color: #747474 !important;
        }

    }
}

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

$themes: (
  light: (
    text: #000,

    //Swiper
    swiper-bullet-bg: black,
    swiper-bullet-bg-main: #66FFCC,


    badge-bgcard: rgb(255, 255, 255),
    badge-border-color: rgba(210, 199, 199, 0.851),
    badge-header-color: rgb(0, 0, 0),
    badge-p-color: rgb(86, 91, 99),
    
    //Team

    team-border-color: rgba(255, 255, 255, 0.543),
    team-box-shadow1: rgba(134, 134, 154, 0.25),
    team-box-shadow2: rgba(255, 255, 255, 0.3),
    team-image-box-shadow: rgba(255, 255, 255, 0.175),
    team-circle: #000,
    team-circle-hover: rgba(243, 229, 229, 0.6),
    team-title: rgb(0,0,0,0.5),
    
    //Careers
    careers-border-color: rgba(225, 218, 218, 0.5),
    careers-bg-color: rgb(233, 227, 227),
    careers-rols-color: rgb(30, 35, 41),
    careers-loc-color: rgb(71, 77, 87),

    // Dist
    dist-percent-color: #FFF,
        
  ),
  dark: (
    text: #FFF,

    //Swiper
    swiper-bullet-bg: white,
    swiper-bullet-bg-main: #66FFCC,

    badge-bgcard: rgba(21, 21, 23, 1),
    badge-border-color: rgba(5, 5, 5, 0.5),
    badge-header-color: rgb(255, 255, 255),
    badge-p-color: rgb(183, 189, 198),

    //Team

    team-border-color: rgba(0, 0, 0, 0.543),
    team-box-shadow1: rgba(50, 50, 93, 0.25),
    team-box-shadow2: rgba(0, 0, 0, 0.3),
    team-image-box-shadow: rgba(0,0,0,.175),
    team-circle: rgb(255, 255, 255),
    team-circle-hover: rgba(65, 54, 54, 0.6),
    team-title: rgb(255,255,255,0.5),
    
    //Careers
    careers-border-color: rgba(5, 5, 5, 0.5),
    careers-bg-color: rgba(21, 21, 23, 1),
    careers-rols-color: rgb(234, 236, 239),
    careers-loc-color: rgb(183, 189, 198),

    // Dist

    dist-percent-color: #FFF,

  ),
);

@mixin themify($themes) {
  @each $name, $values in $themes {
    .#{$name} {
      $theme-map: $values !global;
      @content;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

@include themify($themes) {
  .card-team{
    border-radius: 1rem;
    border-color: themed('team-border-color');
    background: transparent;
    box-shadow: themed('team-box-shadow1') 0px 30px 60px -12px inset, themed('team-box-shadow2') 0px 18px 36px -18px inset;
    padding-bottom: 1em;
    
    .card-team-image-box {
      box-shadow: 0 1rem 3rem themed('team-image-box-shadow') !important;
      padding: 0px;
      overflow: hidden;
      border-radius:100%;
      border: 4px solid transparent;
      position: relative;

      .card-team-social {
        height: 0px;
        margin: 0px;
        padding: 0px;
        left: 25px;
        position: relative;
        opacity: 0;
        transform: translateY(40px);
        transition: .5s;
        .fa-linkedin {
          color: #0976b4;
        }
        .fa-envelope {
          color: #f2a60c;
        }
      }
      
      img {

        margin: auto;
        max-width: 100%;
        height: auto;
      }

      &:hover {
        border: 5px solid themed('team-circle-hover');
      }
    }
  
    .card-team-content-box{
      h3{
        color: themed('text');
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: 500;
        font-size: 18px;
        text-align: center;
        margin: 20px 0 10px;
        line-height: 1.1em;
        .card-team-level {
          font-size: 16px;
          font-weight: 400;
          text-transform: initial;
        }
        .card-team-title {
          color: themed('team-title');
          font-size: 15px;
          font-weight: 500;
          text-transform: initial;
        }
      }
    }
  
    &:hover{
      box-shadow: 0px 0px 22px 0px rgb(0, 0, 0,0.3);
    }
    
    &:hover .card-team-social {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  .swiper-pagination-bullet{
    background: themed('swiper-bullet-bg') !important;
    opacity: var(--swiper-pagination-bullet-inactive-opacity, .5);
  }
  .swiper-pagination-bullet-active-main{
    background: themed('swiper-bullet-bg-main') !important;
    opacity: var(--swiper-pagination-bullet-inactive-opacity, 1);
  }



  .card-badge {
    box-shadow: 0px 0px 22px 1px themed('badge-border-color');
    border-radius: 1rem;
    background: themed('badge-bgcard');
    min-height: 400px;


    .card-header {
      border-radius: 2em !important;
      border: 0px;
      background: transparent;
      img {
          height: 125px;
          width: 125px;
      }
    }
  
    .card-body {
      h5 {
          color: themed('badge-header-color');
      }
      p {
        color: themed('badge-p-color');
      }
    }
  
    .card-footer {
      border-bottom-left-radius: 30px !important;
      border-bottom-right-radius: 30px !important;
    }
  
    .card-header img {
      border-radius: 30px;
    }
  }

  .card-timeline{
    background: transparent;
    border: 1px solid rgba(197, 197, 197, 0.615);
    // border-radius: 5px;
    border-top: 0px;
    border-bottom: 0px;
    height: 100%;
    width: 100%;
    transition: all 0.2s ease;
    &:hover{
      box-shadow: 0 1rem 2px  rgba(102, 163, 46, 0.641)!important;
      border: 1px solid rgba(197, 197, 197, 0.615);
      border-top: 0px;
      border-bottom: 0px;
    }
  }

  .card-dist{
    box-shadow: 0px 0px 22px 1px themed('badge-border-color');
    border-radius: 1rem;
    background: themed('badge-bgcard');
    .sub-header-text{
      font-size: 1em !important;
      margin-right: 10px;
        span{
            position: absolute;
            top: 0px;
            right: 0px;
            left: auto;
            background-color: themed('badge-bgcard');
            border: 2px solid #3f83d2;
            clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
            margin-left: 4px;
            font-size: 15px;
            min-width: 25px;
            min-height: 25px;
            margin-bottom: 6px;
            padding: 6px;

        }
    }
  }

  .card-careers{
    border: 4px solid themed('careers-border-color');
    background: transparent;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
    transition: all .15s ease;
    min-height: 70px;

    &:hover{
      box-shadow: 0 6px 8px 0 rgba(54,77,114,.08),0 2px 4px 0 rgba(54,77,114,.18);
      -webkit-transform: translateY(-2px);
      transform: translateY(-2px);

    }

    .carrers-rols{
      font-weight: 600;
      font-size: 17px;
      line-height: 26px;
      margin: 0;
      transition: all .15s ease;
      color: themed('careers-rols-color');
    }

    .carrers-location{
      color: themed('careers-loc-color');
    }
  }

};

@include media-breakpoint-down(md) {
  .card-team {
    .card-team-image-box {
      width: 150px;
      height: 150px;
      .card-team-social { 
        top: 100px;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .card-team {
    .card-team-image-box {
      width: 175px;
      height: 175px;
      .card-team-social { 
        top: 120px;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .card-team {
    .card-team-image-box {
      width: 200px;
      height: 200px;

      .card-team-social { 
        top: 140px;
      }
    }
  }
}


$themes: (
  light: (
    color: #000,
    color-mobile: #FFF,
  ),
  dark: (
    color: #FFF,
    color-mobile: #FFF,
  )
);

@mixin themify($themes) {
  @each $name, $values in $themes {
    .#{$name} {
      $theme-map: $values !global;
      @content;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}


@include themify($themes) {
  .scrolldown {
    --sizeX: 30px;
    --sizeY: 50px;
    position: relative;
    width: var(--sizeX);
    height: var(--sizeY);
    margin-left: var(sizeX / 2);
    border-radius: 50px;
    box-sizing: border-box;
  
    .chevrons {
      padding: 6px 0 0 0;
      // margin-left: -3px;
      // margin-top: 48px;
      width: 30px;
      display: flex;
      flex-direction: column;
      
      &.chevrons-tech{
        margin-left: -3px;
      }
  
      .chevrondown {
        margin-top: -6px;
        position: relative;
        border: solid themed('color');
        border-width: 0 3px 3px 0;
        display: inline-block;
        width: 10px;
        height: 10px;
        transform: rotate(45deg);

        &:nth-child(odd) {
          animation: pulse 500ms ease infinite alternate;
        }
        
        &:nth-child(even) {
          animation: pulse 500ms ease infinite alternate 250ms;
        }
      }

      .chevrondown-mobile {
        margin-top: -6px;
        position: relative;
        border: solid themed('color-mobile');
        border-width: 0 3px 3px 0;
        display: inline-block;
        width: 10px;
        height: 10px;
        transform: rotate(45deg);


        &:nth-child(odd) {
          animation: pulse 500ms ease infinite alternate;
        }
        
        &:nth-child(even) {
          animation: pulse 500ms ease infinite alternate 250ms;
        }

      }
    }
  }
  
  @keyframes scrolldown-anim {
    0% {
      opacity: 0;
      height: 6px;
    }
    40% {
      opacity: 1;
      height: 10px;
    }
    80% {
      transform: translate(0, 20px);
      height: 10px;
      opacity: 0;
    }
    100% {
      height: 3px;
      opacity: 0;
    }
  }
  @keyframes pulse {
    from {
      opacity: 0;
    }
    to {
      opacity: 0.5;
    }
  }
}

  
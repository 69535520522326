@use '../abstracts/colors' as *;

@mixin themify($themes-main) {
    @each $name, $values in $themes-main {
      .#{$name} {
        $theme-map: $values !global;
        @content;
      }
    }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}
  

@include themify($themes-main) {
   #landing{
    .iphonex {
      width: 16em;
      height: 30em;
      display: flex;
      position: relative;
      transform: rotate(0deg);
      transition: all cubic-bezier(0.36, 0.41, 0.38, 1) 0.4s;
      z-index: 10;
  
      &:hover{
        margin-right: 0%;
        .back{
          box-shadow: 0 0 10em rgba(70, 70, 70, 0.2);
        }
  
      }
  
      .back{
        display: flex;
        flex: 1;
        margin: 0.2em;
        border-radius: 3em;
        position: absolute;
        z-index: 1;
        width: calc( 100% - 0.6em );
        height: calc( 100% - 0.6em );
        top: 0;
        right: 0;
        box-shadow: 0 0 10em rgba(70, 70, 70, 0.1);
        transition: all 0.4s;
      }
  
      .front{
        display: flex;
        flex: 1;
        background-color: #292c2d;
        border-radius: 3em;
        margin: 0.2em;
        overflow: hidden;
        position: relative;
        box-shadow: 0 0.1em 0.4em rgba(255, 255, 255, 0.1) inset;
        z-index: 10;
      }
  
      .screen{
        display: flex;
        flex: 1;
        background-image: linear-gradient(to bottom, #000000, #050404, #090909, #0d0d0d, #111010);
        margin: 0.4em;
        border-radius: 2.6em;
        border: solid 0.2em #121415;
        position: relative;
        z-index: 10;
  
        .screen__view{
          display: flex;
          flex: 1;
          margin: 0.6em;
          border-radius: 2em;
          overflow: hidden;
          position: relative;
          width: 100%;  
          position: relative;
          align-items: center;
          justify-content: center;
  
          &::after{
            transform:  translateY(100%) scaleX(1.4);
            opacity: 0.3;
            transition: all ease 0.5s 0.1s;
          }
  
          &::before{
            transform:  translateY(100%) scaleX(1.4);
            opacity: 1;
            z-index: 40;
            transition: all ease 0.6s;
          }
  
          .dribbbleLogo{
            svg{
              width: 8em;
              height: 8em;
            }
            position: absolute;
            z-index: 10;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all ease 1s;
  
            &.white{
              width: 0.9em;
              height: 0.9em;
              border: solid 0.07em #fff;
              position: relative;
              margin-left: 0.3em;
              background: transparent;
              box-sizing: border-box;
            }
  
          }
  
          .hello{
            opacity: 0;
            font-size: 1.2em;
            color: rgb(0, 0, 0);
            position: absolute;
            z-index: 60;
            opacity: 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            transition: all ease 0.3s;
            transform: translateY(0%); // 40%
            font-weight: 400;
          }
  
          &:after,
          &:before{
            content: "";
            position: absolute;
            z-index: 1;
            width: 50em;
            height: 50em;
            border-radius: 50%;
            bottom: 0;
          }
  
        }
  
        .screen__front{
          position: absolute;
          width: 50%;
          background-color: #000000;
          height: 1.8em;
          border-radius: 0 0 0.9em 0.9em;
          right: 25%;
          top: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-bottom: 0.3em;
          box-sizing: border-box;
          margin-top: 0.5em;
          z-index: 999;
  
          .iphonex .screen .screen__front::after{
            left:1.4em;
            transform: translateX(-100%);
            border-left: 0;
            border-radius: 0 0.7em 0 0;
          }
          .iphonex .screen .screen__front::before{
            right:0.4em;
            transform: translateX(100%);
            border-right: 0;
            border-radius: 0.7em 0 0 0;
          }
  
          &-speaker{
            background: #222626;
            border-radius: 0.2em;
            height: 0.35em;
            width: 28%;
          }
          &-camera{
            height: 0.35em;
            width: 0.35em;
            background: #272727;
            margin-left: .5em;
            border-radius: 50%;
            margin-right: -0.8em;
          }
  
          &::after,
          &::before{
            content: "";
            width: 10%;
            height: 50%;
            position: absolute;
            background: transparent;
            top: -0.3em;
            border: solid 0.4em linear-gradient(to bottom, #000000, #050404, #090909, #0d0d0d, #111010);
            border-bottom: 0;
          }
          
        }

  
      }
  
      .front__line{
        background: linear-gradient(to right, #515455, #454748, #515455);
        position: absolute;
        z-index: 8;
        width: 100%;
        height: 0.5em;
        top: 10%;
  
        &-second {
          top: 90%
        }
        
      }
  
      .phoneButtons{
        width: 1em;
        height: 3.5em;
        position: absolute;
        z-index: 2;
        background: linear-gradient(to bottom, #212324, #2b2e31,#212324);
        box-shadow: 0 0 0.4em rgba(255, 255, 255, 0.1) inset;
        border-radius: 2px;
      }
      
      .phoneButtons-right{
        right: 0;
        top: 30%;
      }
  
      .phoneButtons-left{
        left: 0;
        top: 15%;
        height: 2em;
      }
  
      .phoneButtons-left2{
        left: 0;
        top: 25%;
        height: 3em;
      }
  
      .phoneButtons-left3{
        left: 0;
        top: calc( 25% + 4em);
        height: 3em;
      }
      
    }

   }


}

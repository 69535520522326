@use '../abstracts/colors' as *;
@use './devices' as *;
@use '../component/card';
@use './button.scss';
@use '../base/careers';
@use './how';
@use './faq';

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

body {
    background-color: transparent;
    height: 100%;

    &::-webkit-scrollbar {
        width: 0.5em;
    }
     
    &::webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
        background-color: #F5F5F5;
    }
     
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    }

    &.light {
        &::-webkit-scrollbar {
            background-color: $light-bgcolor;
        }
        &::-webkit-scrollbar-thumb {
            background-color: rgb(25, 25, 25,0.7);
        }
    }

    &.dark {
        &::-webkit-scrollbar {
            background-color: $dark-bgcolor;
        }
        &::-webkit-scrollbar-thumb {
            background-color: rgba(194, 190, 190, 0.7);
        }
    }

}

@mixin themify($themes-main) {
    @each $name, $values in $themes-main {
      .#{$name} {
        $theme-map: $values !global;
        @content;
      }
    }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}
  

@include themify($themes-main) {
    background-color: themed('bgcolor');
    .bg{
        &-main{
            background-color: themed('bgcolor-plt1');
        }
        &-plt1{
            background-color: themed('bgcolor-plt1');
        }
        &-plt2{
            background-color: themed('bgcolor-plt2');
        }
        &-plt3{
            background-color: themed('bgcolor-plt3');
        }
        &-grd1{
            background-image: linear-gradient(to bottom, #53474e, #443c44, #353238, #28272d, #1c1d21, #17191c, #121417, #0b0e11, #0b0e11, #0b0e11, #0b0e11, #0b0e11);
        }
    }

    .color-ioid-green {
        background-image: -webkit-linear-gradient(right,#66FFCC 90%,#66FFCC) !important;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .choise-button{
        a{
            position: relative;
            background: #444;
            text-decoration: none;
            text-transform: uppercase;
            font-size: 1.5em;
            letter-spacing: 0.1em;
            font-weight: 400;
            padding: 10px 10px;
            margin: 0.2em;
            transition: 0.5s;
            color: #FFF;
            &:hover{
                letter-spacing: 0.25em;
                background: var(--clr);
                color: var(--clr);
                box-shadow: 0 0 35px var(--clr);
                i::before{
                    left: 0%;
                    transform: translateX(-50%) rotate(45deg);
                    box-shadow: 40px 40px var(--clr);
                }
                i::after{
                    left: 100%;
                    transform: translateX(-50%) rotate(-45deg);
                    box-shadow: 38px -39px var(--clr);
                }
            }
            &::before{
                content: '';
                position: absolute;
                inset: 2px;
                background: #27282c;
            }
            span{
                position: relative;
                z-index: 1;
            }
            i {
                position: absolute;
                inset: 0;
                display: block;
                &::before{
                    content: '';
                    position: absolute;
                    top: -6px;
                    left: 100%;
                    transform: translateX(-50%);
                    width: 10px;
                    height: 10px;
                    background: #27282c;
                    border: 2px solid var(--clr);
                    transition: 0.5s;
                }
                &::after{
                    content: '';
                    position: absolute;
                    bottom: -6px;
                    left: 0%;
                    transform: translateX(-50%);
                    width: 10px;
                    height: 10px;
                    background: #27282c;
                    border: 2px solid var(--clr);
                    transition: 0.5s;
                }
            }
        }
    }

    .header-text {
        font-weight: 500;
        font-size: 50px;
        color: themed('header-text-color');
    }

    .sub-header-text {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: themed('header-text-color');
    }

    .sub-text {
        color: themed('sub-text-color') !important;
    }

    .sub-header-svg {
        // height: 100px;
        // width: 100px;
        // fill: aqua;
    }

    .vh-50{
        height: 50vh;
    }

    /* li ul */

    .list-type {
        overflow:hidden;
        padding-left: 1em;
        li {
            list-style:none; 
            position:relative; 
            padding:0 0 0 1.5em;
            margin:0 0 .1em 0;
            transition: .12s;
            &::before{
                position:absolute;
                font-family:Arial;
                color:themed('li-color');
                top:0;
                left:0;
                text-align:center; 
                opacity:.5;
                line-height:.75; 
                transition:.5s;
              }
            &::before{
                content:'';
                width:10px; height:10px;
                background:themed('li-color');
                border-radius:3px;
                line-height:0;
                top:.27em; left:5px;
            }
        }
    }

    .list-type-hover { 
        overflow:hidden;

        li {
            list-style:none; 
            position:relative; 
            padding:0 0 0 1.5em;
            margin:0 0 .3em 0;
            transition: .12s;

            &::before{
              position:absolute;
              font-family:Arial;
              color:themed('li-color');
              top:0;
              left:0;
              text-align:center; 
              font-size:2em; 
              opacity:.5;
              line-height:.75; 
              transition:.5s;
            }
            
            &::before{
              content:'';
              width:10px; height:10px;
              background:themed('li-color');
              border-radius:3px;
              line-height:0;
              top:.27em; left:5px;
            }

            &:hover{
                color:themed('li-color');
                &::before{
                  transform:scale(2); 
                  opacity:1; 
                  text-shadow:0 0 4px;
                  transition:.1s;
                }
              }
            
            &:hover::before{
              transform:none; 
              border-radius:5px; 
              width:25px; 
              left:-10px;
              background:themed('li-hover-color');
            }
        }
    }

    .loader{
        width: 100px;
        height: 102px;
        border-radius: 100%;
        top: 45%;
        left: calc(50% - 50px);
        animation: spin 5s infinite linear;

        .circle{
            width: 100%;
            height: 100%;
            position: absolute;
        }

        .inner{
            width: 100%;
            height: 100%;
            border-radius: 100%;
            border: 5px solid transparentize(#66FFCC, 0.3);
            border-right: none;
            border-top: none;
            background-clip: padding;
            box-shadow: inset 0px 0px 10px transparentize(#66FFCC, 0.85);
        }

        @keyframes spin{
            from{
                transform: rotate(0deg);
            }
                
            to{
                transform: rotate(360deg);
            }
        }
                
        @for $i from 0 through 2{
            .circle:nth-of-type(#{$i}){
                transform: rotate($i * 70deg);
                .inner{
                    animation: spin 2s infinite linear;
                }
            }
        }  

    }


    /** START HEADER **/

    #IoID-header {
        background-color: themed('navbar-bgcolor')  !important;

        .IoID-logo {
            height: 60px;
            width: 60px;
        }

        #theme-change-button {
            svg[data-icon="moon"] {
                color: #c9c9c9;
            }
            svg[data-icon="sun"] {
                color: #FFE484;
            }
        }

        .navbar {
            background-color: transparent;
        }
    
        .navbar-brand {
            span {
                color: themed('navbar-text-color');
                font-size: 40px;
                font-weight: 300;
                font-family: Inter;
            }
        }
    
        .nav-link {
            color: themed('navbar-text-color') !important; 
        }
    
        .nav-link:hover {
            color: themed('navbar-dropdown-text-hover-color') !important; 
        }
    
        .dropdown-menu {
            background-color: themed('navbar-dropdown-bgcolor');
            border: 2px solid themed('navbar-dropdown-border-bgcolor');
            border-radius: 10px;
            min-width: 12rem;
        }
    
        .dropdown-menu.show a {
            color: themed('navbar-text-color') !important; 
            color: themed('navbar-dropdown-bgcolor');
        }
    
    
        .dropdown-menu.show a:hover {
            background: themed('navbar-dropdown-text-hover-bgcolor');
            color: themed('navbar-dropdown-text-hover-color');
        }
    }

    /** END HEADER **/ 

    /** START FOOTER **/ 

    #IoID-Footer{
        overflow: hidden;

        .IoID-logo {
            height: 50px;
            width: 50px;
        }

        .nav-link {
            color: themed('navbar-text-color') !important; 
            padding: 0px;

            &.ext-link{
                &::after{
                    margin-left: 0.125em;
                    margin-right: 0.3em;
                    display: inline;
                    content: "↗";
                    transition: all 0.1s ease-in-out 0s;
                    font-style: normal;
                }
            }

        }
    }

    #footer-top-social-media {
        border-top: 3px solid themed('footer-social-border-color');
        a {
            color: themed('color') !important;
            text-decoration: none;
        }
        .fa-linkedin-in {
            color: #0976b4;
        }
        .fa-medium {
            color: themed('social-fa-medium');
        }
        .fa-youtube {
            color: #e52d27;
        }
        .fa-twitter {
            color: #55acee;
        }
        .fa-discord {
            color: #7289da;
        }
        .fa-telegram {
            color: #229ED9;
        }
        .fa-instagram {
            color: #E1306C;
        }
    }

    #IoID-Footer-bottom {
        border-top: 3px solid themed('footer-social-border-color') !important;
    }

    .footer-brand-svg{
        a {
            color: #707A8A;
            &:hover {
                color: themed('footer-brand-hover-color') !important;
            }
        }
    }
    
    .footer-header-text {
        color: themed('footer-header-color') !important;
    }

    .copyrigt{
        color: themed('color');
        text-decoration: none;
    }

    /** END FOOTER **/ 

    // Main Page

    #landing{

        .img-main{
            width: 100%;
            height: 100%;
        }

        #IoID-home{

            .landing-content{
    
                .landing-header{
                    font-family: 'Times New Roman', serif;
                    font-weight: 600;
                    margin-top: 69px;
                    margin-bottom: 16px;
                    font-size: 56px;
                    color: themed('header-text-color');
                }

                .landing-sub-header{
                    font-family: 'Times New Roman', serif;
                    font-weight: 400;
                    margin-bottom: 16px;
                    line-height: 1.25;
                    font-size: 32px;
                    color: themed('header-text-color');
                }

                .landing-sub-text{
                    font-weight: 300;
                    margin-bottom: 16px;
                    line-height: 1.25;
                    font-size: 20px;
                    color: themed('header-text-color');
                }


                .custom-btn {
                    align-items: center;
                    appearance: none;
                    background: white;
                    border-radius: 4px;
                    border-width: 0;
                    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#FFFFFF 0 -3px 0 inset;
                    box-sizing: border-box;
                    color: #36395A;
                    cursor: pointer;
                    display: inline-flex;
                    height: 48px;
                    justify-content: center;
                    line-height: 1;
                    list-style: none;
                    overflow: hidden;
                    padding-left: 16px;
                    padding-right: 16px;
                    position: relative;
                    text-align: left;
                    text-decoration: none;
                    transition: box-shadow .15s,transform .15s;
                    user-select: none;
                    -webkit-user-select: none;
                    touch-action: manipulation;
                    white-space: nowrap;
                    will-change: box-shadow,transform;
                    font-size: 18px;

                    &:focus {
                        box-shadow: #8FE3CF 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #8FE3CF 0 -3px 0 inset;
                    }
                    
                    &:hover {
                        box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #8FE3CF 0 -3px 0 inset;
                        transform: translateY(-2px);
                    }
                    
                    &:active {
                        box-shadow: #8FE3CF 0 3px 7px inset;
                        transform: translateY(2px);
                    }
                }
                  
            }
        }

        #IoID-web3{
            .card{
                background: rgba(255,255,255,0.1);
                box-shadow: 0 25px 45px rgb(0 0 0 / 25%);
                border-right: 0 25px 45px rgba(255,255,255,0.1);
                border-bottom: 0 25px 45px rgba(255,255,255,0.1);
                background: linear-gradient(145deg,#171717, #121112);
                box-shadow: inset 2px 2px 0px #141414, inset -2px -2px 0px #1c1c1c;
                backdrop-filter: blur(25px);
                border-radius: 10px;
                display: flex;
                z-index: 1;
    
                &::before{
                    position: absolute;
                    content: "";
                     transform: translate(-50%,-50%);
                     border-radius: inherit;
                     background: linear-gradient(145deg, #262626, #606060);
                     box-shadow: 11px 11px 22px #141414, -11px, -11px 22px #525252;
                }
    
                .ring {
    
                    &-right{
                        position: absolute;
                        height: 500px;
                        width: 500px;
                        border-radius: 50%;
                        background: transparent;
                        border: 50px solid rgba(255, 255, 255, 0.1);
                        bottom: -250px;
                        right: -250px;
                        box-sizing: border-box;
                        &::after {
                            content: "";
                            position: absolute;
                            height: 600px;
                            width: 600px;
                            border-radius: 50%;
                            background: transparent;
                            border: 30px solid rgba(255, 255, 255, 0.1);
                            bottom: -80px;
                            right: -110px;
                            box-sizing: border-box;
                        }
    
                    }
    
                    &-left{
                        position: absolute;
                        height: 500px;
                        width: 500px;
                        border-radius: 50%;
                        background: transparent;
                        border: 50px solid rgba(255, 255, 255, 0.1);
                        bottom: -250px;
                        left: -250px;
                        filter: blur(5px);
                        box-sizing: border-box;
                        &::after {
                            content: "";
                            position: absolute;
                            height: 600px;
                            width: 600px;
                            border-radius: 50%;
                            background: transparent;
                            border: 30px solid rgba(255, 255, 255, 0.1);
                            bottom: -80px;
                            left: -110px;
                            box-sizing: border-box;
                        }
                    }
                }
            }
        }

        #IoID-platforms{
            .plaform-brand{
                border-radius: 8px;
                padding-top: 8px;
                svg{
                    height: 40px;
                    width: 40px;
                    color: themed('platform-svg-color');
                }
                &:hover{
                    background-color: themed('platform-hover-svg-color');
                }
            }
        }

        #IoID-roadmap{
            &.roadmap {
                font-family: MagistralC-Bold,Helvetica Neue,Helvetica,Arial,sans-serif;
                font-style: normal;
                font-weight: 400;
        
                .tl-header-text{
                    font-size: 23px;
                    line-height: 33px;
                    color: themed('header-text-color');
                }

                .roadmap-body {
                    color: rgba(196, 190, 190, 0.853);
                    font-size: 18px;
                    margin-top: 90px;
                }
        
                .swiper{
                  overflow-x: hidden;
        
                  .swiper-wrapper{
                    margin-top: 3em;
        
                    .swiper-slide{
                      // filter: blur(4px);
                      height: auto !important;
                    }
        
                    .swiper-slide-active{
                      // filter: blur(0px);
                    }
        
                  }
                }
        
                .swiper-button-prev{
                  color:rgba(27, 216, 153, 0.615);
                  left: 0px;
                  right: auto;
                  top: 5% !important;
                  position: absolute !important;
                  &:hover{
                    color: rgba(27, 216, 153, 1);
                  }
                }
        
                .swiper-button-next{
                  color:rgba(27, 216, 153, 0.615);
                  left: 100px;
                  right: auto;
                  top: 5% !important;
                  position: absolute !important;
                  &:hover{
                    color: rgba(27, 216, 153, 1);
                  }
                }
                
            }
        }
    }

    // Partners

    #IoID-partners{

        #logo-grid {
            align-content: space-around;
            align-items: flex-start;
            box-sizing: border-box;
            display: flex ;
            flex-flow: row wrap;
            justify-content: center;
            margin: auto;
        }

        .partner-logo {

            &:hover, &-2:hover{
                img{
                    border-radius: 5px;
                    background-color: themed('partners-hover-svg-color');
                }
            }

            &-2 img{
                display: block;
                max-width: 8em;
                width: 100%;

            }
            
            img{
                padding: 5px;
                display: block;
                max-width: 5em;
                width: 100%;
            }

        }
    }

   // Technical


}

@include media-breakpoint-up(xs) {
    .header-text {
        font-size: 24px !important;
    }

    .sub-header-text {
        font-size: 20px !important;
    }

    .sub-text {
        font-size: 14px;
        padding: 5px 0px 10px 0px;
    }
}
// sm: 576px,
@include media-breakpoint-up(sm) {
    .header-text {
        font-weight: 600 !important;
        font-size: 24px !important;
        line-height: 32px !important;
    }

    .sub-header-text {
        font-weight: 500 !important;
        font-size: 20px !important;
        line-height: 28px !important;
    }

    .sub-text {
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 20px !important;
        padding: 5px 0px 10px 0px;
    }
}
// md: 768px,
@include media-breakpoint-up(md) {
    .header-text {
        font-weight: 600 !important;
        font-size: 32px !important;
        line-height: 40px !important;
    }

    .sub-header-text {
        font-weight: 600 !important;
        font-size: 24px !important;
        line-height: 32px !important;
    }

    .sub-text {
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 24px !important;
        padding: 5px 0px 10px 0px;
    }
}
// lg: 992px,
@include media-breakpoint-up(lg) {
    .header-text {
        font-size: 40px !important;
        font-weight: 600 !important;
        line-height: 48px !important; 
    }

    .sub-header-text {
        font-weight: 600 !important;
        font-size: 24px !important;
        line-height: 32px !important;
    }

    .sub-text {
        line-height: 24px;
        font-size: 16px;
        font-weight: 400;
        padding: 10px;
    }

}

